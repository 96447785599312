import React, { useEffect, useState, useRef } from 'react';
import Grid from './Grid';
import StrainCards from './StrainCards';
import { useDispatch, useSelector } from 'react-redux';
import { FetchStrains, FetchSearchResults } from '../actions/Apicalls';
import { CHANGE_PAGE } from '../reducers';
import Search from './Search';

const StrainList = () => {
  const dispatch = useDispatch();
  const strains = useSelector((state) => state.strains);
  const currentpage = useSelector((state) => state.page);
  const searchResults = useSelector((state) => state.searchResults);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortby, setSortby] = useState('id');
  const [sortdir, setSortDir] = useState('desc  ');
  const [search, setsearch] = useState('');
  const [searchStrainData, setSearchData] = useState([]);
  const [state, setState] = useState('');
  const timeOut = useRef(null);

  async function searchData() {
    // if (sessionStorage.strainState.length > 3) {
    //   setState(JSON.parse(sessionStorage.strainState));
    //   // console.log(JSON.parse(sessionStorage.strainState), 'STATE1');
    // } else {

    console.log(state, 'STATE Length');
    if (state.length === 0) {
      console.log(state, 'STATE Length I DIDNT RUN');

      dispatch(
        FetchSearchResults(
          // `https://strainwatch-v1.herokuapp.com/api/strains?limit=200&name=${searchTerm}&sortby=${sortby}&sortdir=${sortdir}`
          `https://strainwatch-v1.herokuapp.com/api/strains`
        )
      );
      setState(searchResults);
    }
    // sessionStorage.setItem('strainState', JSON.stringify(searchResults));
    // }
    // console.log(sessionStorage.strainState.length, 'check');
  }

  let searchres = searchResults.length > 0;
  useEffect(() => {
    dispatch(
      FetchStrains(
        `https://strainwatch-v1.herokuapp.com/api/strains?page=${currentpage}&limit=20`
      )
    );
    console.log('STATEALOT');
    searchData();
  }, [currentpage, dispatch, searchres, state.length === 0]);

  useEffect(() => {
    if (state.length > 3) {
      const results = state.filter((strains) =>
        strains.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchData(results);
    } else {
      const results = searchResults?.filter((strains) =>
        strains.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchData(results);
    }
  }, [searchTerm]);

  const handleChange = (event) => {
    // console.log(event.target.value)
    const { value } = event.target;
    setsearch(value);

    clearTimeout(timeOut.current);
    timeOut.current = setTimeout(() => {
      setSearchTerm(search);
    }, 500);
  };

  console.log(searchResults, searchStrainData, 'STATE');

  return (
    <div style={{ textAlign: 'center', marginTop: '3.5%' }}>
      {/* <Search /> */}
      <br />
      <form>
        <label htmlFor='name'>
          <input
            id='name'
            type='text'
            name='textfield'
            placeholder='Search'
            value={search}
            onChange={handleChange}
          />
        </label>
      </form>
      <br />
      <Grid>
        {(strains && !searchTerm) || searchTerm?.length <= 1
          ? strains?.results?.map((strain) => (
              <StrainCards
                key={strain.id}
                strain={strain}
                sname={strain.name.replace(/\s+/g, '_')}
              />
            ))
          : null}

        {state && searchTerm?.length > 1
          ? searchStrainData
              ?.map((strain) => (
                <StrainCards
                  key={strain.id}
                  strain={strain}
                  sname={strain.name.replace(/\s+/g, '_')}
                />
              ))
              .slice(0, 10)
          : null}
        {console.log(searchTerm, 'TERM')}
      </Grid>
      <div
        style={{
          width: '100%',
          marginTop: '3%',
          marginBottom: '1%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <button
          disabled={!strains.previous ? true : false}
          onClick={() =>
            dispatch({
              type: CHANGE_PAGE,
              payload: currentpage === 1 ? currentpage : currentpage - 1,
            })
          }
        >
          previous
        </button>
        <input
          type='number'
          style={{ width: '45px', textAlign: 'center', fontWeight: 'bolder' }}
          placeholder={currentpage}
          value={Number(
            currentpage <= 0
              ? dispatch({ type: CHANGE_PAGE, payload: 1 })
              : currentpage
          )}
          onChange={(e) =>
            dispatch({
              type: CHANGE_PAGE,
              payload: Number(e.target.value),
            })
          }
        />
        <button
          disabled={!strains.next ? true : false}
          onClick={() => {
            dispatch({
              type: CHANGE_PAGE,
              payload: currentpage < 0 ? 2 : currentpage + 1,
            });
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default StrainList;
