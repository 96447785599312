import Rating from '@material-ui/lab/Rating';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';

export const StyledRating = withStyles({
  // fontSize: '2rem',
  iconFilled: {
    color: '#ff6d75',
  },
  iconHover: {
    color: '#ff3d47',
  },
})(Rating);

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  stars: {
    fontSize: '2rem',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[600],
    position: 'absolute',
    top: -1,
    left: 80,
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      left: 0,
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -16,
    marginLeft: -12,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 1, 1),
    },
  },
}));

export const StyledNode = styled.div`
  padding: 5px;
  border-radius: 8px;
  display: inline-block;
  border: 1px solid red;
`;

export const StrainTitle = styled.h1`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 2.6rem;
  line-height: 1;
`;

export const StyledStrainHeader = styled.div`
  margin-bottom: 4rem;
  text-align: center;
`;

export const StrainImg = styled.img`
  border-radius: 1rem;
  margin-top: 2rem;
`;

export const StyledDescText = styled(Typography)`
  /* padding: 1rem; */
  padding: 5%;
`;

export const StyledStrainDiv = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 6rem 1rem;
  background-color: #192831;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;

export const Inputtextarea = styled.textarea`
  display: flex;
  line-height: 1.44em;
  border: 0;
  outline: none;
  padding: 0;
  resize: none;
  width: 100%;
  height: 25vh;
  font-size: 1.8rem;

  ::placeholder {
    font-size: 1rem;
    color: gray;
  }
`;
export const StyledTitleInput = styled.input`
  line-height: 1.44em;
  font-size: 1.5rem;
  width: 100%;
  max-width: 660px;
  margin-bottom: 1%;
  height: 35px;
  @media screen and (max-width: 600px) {
  }
  :invalid {
    box-shadow: 0 0 5px 1px red;
  }

  :focus:invalid {
    box-shadow: none;
  }
  ::placeholder {
    font-size: 1rem;
    color: gray;
    @media screen and (max-width: 600px) {
      font-size: 1rem;
    }
  }
`;

export const StyledButton = styled(Button)`
  &&& {
    color: white;

    @media screen and (max-width: 600px) {
      width: 85%;
      height: 5.5vh;
    }

    &:hover {
      background-color: rgb(24, 24, 24);
    }

    font-weight: bold;
    margin-top: 2%;
    margin-bottom: 1%;
    background: black;
    width: 50%;
  }
`;

export const StyledFab = styled(Fab)`
  position: absolute;
  left: 85px;
  /* top: -1px; */
  zindex: 1px;

  &&& {
    /* background: green; */
    color: white;

    &:hover {
      background-color: black;
    }

    font-size: 0;
  }
  .checkIcon {
    color: white;
    font-size: xx-large;
  }
`;

export const StyledRatingform = styled.div`
  width: 200;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const StyledReviewDiv = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: text;
  padding: 18px;
  max-width: 630px;
`;

export const StyledStrainReviewDiv = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 960px;
  padding: 15px;
`;

export const StyledStrainReviewFormDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledStrainReviewMap = styled.div`
  h2 {
    color: #ffb90f;
    align-self: center;
    margin-bottom: 0;
    text-decoration: underline;
  }
  h3 {
    margin-bottom: 0;
  }
  h4 {
    margin: 0;
  }
  p {
    color: white;
    font-size: 0.8rem;
  }
  margin: 4px, 4px;
  padding: 10px;
  background-color: #5a4e4e;
  height: 80vh;
  overflow-x: auto;
  text-align: justify;

  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 30%;
  border-left: 1px dashed black;
`;
export const StyledStrainCardReviewdiv = styled.div`
  h4 {
    margin-top: 1%;
  }
  h3 {
    margin-bottom: 0.5%;
  }
  p {
    font-size: 1.1rem;
  }
`;

export const StyledSectionHead = styled.div`
  margin-top: 9rem;
  margin-bottom: 3%;
  padding: 1rem 0;
  font-size: 1.4rem;
`;

export const StyledEffectdiv = styled.div`
  text-align: center;
  height: 50vh;
  margin-bottom: 1%;
`;
