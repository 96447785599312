// Dependancies
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
// Material UI
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Fab from '@material-ui/core/Fab';
import CropFreeIcon from '@material-ui/icons/CropFree';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Avatar from '@material-ui/core/Avatar';

// Icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import FaceIcon from '@material-ui/icons/Face';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import logo from '../imgs/outline-extra-thick.png';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// Styles
import { useStyles } from '../styles/StyledNavBar.js';
import { useHistory, Link } from 'react-router-dom';

const NavBar = (props) => {
  // const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const loggedin = useSelector((state) => state.loggedin);

  const [open, setOpen] = useState(false);
  const { push } = useHistory();

  // const toggleDrawer = () => {
  //   setOpen(!open);
  // };

  const toggleDrawer = (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen(!open);
  };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };
  // *** NOTE Error on List Item icon, might be how it loads in and doesn't recognize an SVG right away - Look into at Later time
  const drawer = (
    <>
      <div className={classes.drawerHeader}>
        <IconButton onClick={() => setOpen(false)}>
          {theme.direction === 'ltr' ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>
      <Fab aria-label='take qr photo' className={classes.fabButton}>
        <CropFreeIcon />
      </Fab>
      <Divider />
      <List>
        {['Strain List', 'Favorites'].map((text, index) => (
          <ListItem
            button
            onClick={() =>
              'Strain List' === text ? push('/strains') : console.log('no')
            }
            key={text}
          >
            <ListItemIcon>
              {index % 2 === 0 ? <DashboardIcon /> : <FavoriteIcon />}{' '}
            </ListItemIcon>
            <h2 style={{ fontWeight: '400' }}> {text} </h2>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem>Hello {props?.currentuser?.email},</ListItem>
        {['Profile'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <FaceIcon /> : <ExitToAppIcon />}{' '}
            </ListItemIcon>
            <h2 style={{ fontWeight: '400' }}> {text} </h2>
          </ListItem>
        ))}
        {/* <ListItem>Hello {props?.currentuser?.email}</ListItem> */}
        {loggedin ? (
          <ListItem to='/login'>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <Link to='/login'>
              <span
                onClick={() =>
                  localStorage.clear() &
                  sessionStorage.clear() &
                  dispatch({
                    type: 'LOGGED_STATUS',
                    payload: false,
                    status: '',
                  })
                }
              >
                Logout
              </span>
            </Link>
          </ListItem>
        ) : (
          <Button>
            <Link to='/login'>Login</Link>
          </Button>
        )}
      </List>
    </>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            edge='start'
            onClick={toggleDrawer}
            className={clsx(classes.menuButton, open && classes.hide)}
            aria-label='open drawer'
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.logoHeader}>
            <Avatar
              onClick={() => push('/strains')}
              style={{ cursor: 'pointer' }}
              src={logo}
            />
            <h1
              onClick={() => push('/strains')}
              style={{ cursor: 'pointer' }}
              className={classes.title}
            >
              Strain Watch
            </h1>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        open={open}
        variant='temporary'
        anchor='left'
        onBlur={() =>
          setTimeout(() => {
            setOpen(false);
          }, 200)
        }
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {drawer}
      </Drawer>
    </div>
  );
};

export default NavBar;
