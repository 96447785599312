import React, { useEffect } from 'react';
import './App.css';
import StrainList from './Components/StrainList';
import NavBar from './Components/NavBar';
import { Route, Switch } from 'react-router-dom';
import Strains from './Components/Strains';
import REGISTER_LOGIN from './Components/Register_Login';
import { useDispatch, useSelector } from 'react-redux';
import { Fetchusers } from './actions/Apicalls';

function App() {
  const dispatch = useDispatch();
  const currentuser = useSelector((state) => state.currentuser);
  const loggedin = useSelector((state) => state.loggedin);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch({ type: 'LOGGED_STATUS', payload: true });
      setTimeout(() => {
        dispatch(Fetchusers());
      }, 100);
    }
  }, [dispatch, loggedin]);
  return (
    <div className='App'>
      <NavBar currentuser={currentuser} />
      <Switch>
        <Route path='/login'>
          <REGISTER_LOGIN />
        </Route>
        <Route path='/strains/:sname'>
          <Strains />
        </Route>
        <Route path='/strains'>
          <StrainList />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
