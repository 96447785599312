// Dependancies
import React, { useEffect, useState, useCallback } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner';

// Reported Feelings images
import hugface from '../imgs/hugging-face-48.png';
import creativeimg from '../imgs/creativity-48.png';
import happyimg from '../imgs/lol-64.png';
import activeimg from '../imgs/weightlifting-144.png';
import euphoricimg from '../imgs/Euphoric-144.png';
import arousedimg from '../imgs/honeymoon-256.png';
import relaxedimg from '../imgs/floating-guru-80.png';
import upliftedimg from '../imgs/winner-144.png';
import talkativeimg from '../imgs/talkative.png';
import gigglyimg from '../imgs/rolling-on-the-floor-laughing-144.png';
import sleepyimg from '../imgs/napping-150 (2).png';
import hungryimg from '../imgs/hungry.png';

// Components
import { FetchOne } from '../actions/Apicalls';
// Material UI
import FavoriteIcon from '@material-ui/icons/Favorite';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Tree, TreeNode } from 'react-organizational-chart';

// Images
import noImage from '../imgs/green-text-radar.png';

// Styles
import {
  StyledRating,
  StrainImg,
  StrainTitle,
  StyledSectionHead,
  StyledDescText,
  StyledStrainHeader,
  StyledStrainDiv,
  useStyles,
  StyledNode,
  StyledEffectdiv,
} from '../styles/StyledStrains';

//  RECHART IMPORTS
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Legend,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  Text,
  ResponsiveContainer,
  Label,
  LabelList,
  Customized,
} from 'recharts';
import PropTypes from 'prop-types';
import Reviews from './Reviews';
import {
  descriptionSection,
  CustomizedAxisTick,
  CustomizedBarAxisTick,
  renderCustomBarLabel,
} from '../utils/helpers';

// NOTES ---------------------------------------

// Current issues to Fix: Fix placement, put effects above description for Default --   Fix spacing issues before putting in Paralax
//  390 change Terps to flavor name
// Strains not loading find out why!!!!!

// End Notes -------------------------------

const Strains = (props) => {
  const { sname } = useParams();
  const dispatch = useDispatch();
  const strain = useSelector((state) => state.data);
  const isloading = useSelector((state) => state.isloading);
  const currentUser = useSelector((state) => state.currentuser);
  const [hover, setHover] = useState(0);
  const [showReview, setShowReview] = useState(false);
  const [currentReviews, setCurrentReviews] = useState([]);
  const { push } = useHistory();

  const labels = {
    0: 'Select your Rating',
    1: 'Horrible',
    2: 'Poor',
    3: 'Ok',
    4: 'Good',
    5: 'Excellent',
  };

  let dashed = sname.replace(/_/g, ' ');

  let strainid = strain?.id;

  let chkstrainImg = strain?.image === 'None' ? null : strain?.image;

  useEffect(() => {
    dispatch(
      FetchOne(`https://strainwatch-v1.herokuapp.com/api/strains/${dashed}`)
    );
  }, [dispatch, dashed, strainid]);

  const toggleModal = () => {
    if (!localStorage.getItem('token')) {
      alert('Need to be logged in to review this strain!!');
      push('/login');
    } else {
      setShowReview(!showReview);
    }
  };

  // EFFECT SECTION HERE ----------------------

  let effectarr = [
    'Creative',
    'Tingly',
    'Happy',
    'Energetic',
    'Euphoric',
    'Focused',
    'Aroused',
    'Relaxed',
    'Uplifted',
    'Talkative',
    'Giggly',
    'Sleepy',
    'Creative',
    'Hungry',
  ];
  // let effectParsed = JSON.parse(
  //   strain?.reported_feelings?.replace(/"/g, '"') ?? null
  // )?.forEach((e) => effectarr.push(e));

  let effectParsed = strain?.reported_feelings
    ?.replace(/'/g, '')
    .split(',')
    .slice(1, -1)
    ?.forEach((e) => effectarr.push(e.trim()));

  // let effectParsed = [
  //   strain?.reported_feelings?.replace(/'/g, '"').split(',').slice(1, -1),
  // ];
  console.log(typeof effectParsed, 'CHECK');
  // let uniqueEffect = [...new Set(effectarr)];

  // function compressArray(original) {

  //   var compressed = [];
  //   // make a copy of the input array
  //   var copy = [...original]

  //   // first loop goes over every element
  //   for (var i = 0; i < original.length; i++) {

  //     var myCount = 0;
  //     // loop over every element in the copy and see if it's the same
  //     for (var w = 0; w < copy.length; w++) {
  //       if (original[i] === copy[w]) {
  //         // increase amount of times duplicate is found
  //         myCount++;
  //         // sets item to undefined
  //         delete copy[w];
  //       }
  //     }

  //     if (myCount > 0) {
  //       var a = {}
  //       a.value = original[i];
  //       a.count = myCount;
  //       compressed.push(a);
  //     }
  //   }

  //   return compressed;
  // };

  //  REDUCE GRAPH DATA -----

  let effectGraphData = effectarr?.reduce((accumulator, currentValue) => {
    // Get the element in the accumulator array with the same value as the current value, or if none found it'll result in a falsey value which will trigger a short-circuit evaluation using the ||
    let data =
      accumulator[
        accumulator.findIndex((item) => item.value === currentValue)
      ] ||
      // Array.push returns the length of the new array
      // So, a[a.push(foo) - 1] is like saying: push foo to a, and then retrieve that new element (or the element one less then the length of the newly updated array, which is our new element since push always pushes to the end of the array)
      accumulator[
        accumulator.push({
          value: currentValue,
          count: -1,
        }) - 1
      ];

    data.count++;
    data.percentage =
      Math.round((100 * (data.count * 100)) / (effectarr.length - 13)) / 100;

    // Now take the result of the expression in the parenthesis (which is one way or the other an element in the accumulator array), and increment its count property
    // Return the accumulator (reduce makes you return the accumulator)
    return accumulator;
  }, []);

  let totalper = effectGraphData.reduce((a, b) => a + b.percentage, 0);

  let effectGraphDataSort = [...effectGraphData].sort(
    (a, b) => b.percentage - a.percentage
  );

  let effectGraphDataTop3 = effectGraphDataSort.map((e) => e.value).slice(0, 3);
  // TEST MAP FORMAT ??

  // arr.reduce((a, b) => a.set(b, a.get(b) + 1 || 1), new Map());

  // PERCENTAGE CONSOLE ---

  console.log(
    'TOTAL',
    effectGraphData,
    effectGraphDataSort,
    effectGraphDataTop3
  );
  console.log(
    'TOTAL UNIQUE EFFECTS (NO REPEATS):',
    effectGraphData.length,
    'TOTAL EFFECTS INCLUDING REPEATS:',
    effectarr.length - 13,
    'TOTAL % if i ROUND:',
    totalper
  );

  const effectEmoji = {
    Euphoric: <img style={{ width: '48px' }} src={euphoricimg} alt='' />,
    Tingly: <img style={{ width: '48px' }} src={hugface} alt='' />,
    Creative: <img style={{ width: '48px' }} src={creativeimg} alt='' />,
    Happy: <img style={{ width: '48px' }} src={happyimg} alt='' />,
    Energetic: <img style={{ width: '48px' }} src={activeimg} alt='' />,
    Aroused: <img style={{ width: '48px' }} src={arousedimg} alt='' />,
    Relaxed: <img style={{ width: '48px' }} src={relaxedimg} alt='' />,
    Uplifted: <img style={{ width: '48px' }} src={upliftedimg} alt='' />,
    Talkative: <img style={{ width: '48px' }} src={talkativeimg} alt='' />,
    Giggly: <img style={{ width: '48px' }} src={gigglyimg} alt='' />,
    Sleepy: <img style={{ width: '48px' }} src={sleepyimg} alt='' />,
    Hungry: <img style={{ width: '48px' }} src={hungryimg} alt='' />,
  };

  // --------------------------------------------------------------------------------

  let effectSection = (
    <StyledEffectdiv className='effects'>
      <h2 style={{ fontSize: '2rem' }}>Effects</h2>
      <span style={{ fontSize: '1.5rem' }}>Top 3 Effects:</span>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <p>
          {effectGraphDataTop3[0]} <br /> {effectEmoji[effectGraphDataTop3[0]]}{' '}
        </p>
        &nbsp; &nbsp; &nbsp;
        <p>
          {effectGraphDataTop3[1]} <br />
          {effectEmoji[effectGraphDataTop3[1]]} &nbsp;
        </p>
        &nbsp; &nbsp; &nbsp;
        <p>
          {effectGraphDataTop3[2]}
          <br />
          {effectEmoji[effectGraphDataTop3[2]]}
        </p>
      </div>
      <RadarChart
        margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
        cy={190}
        outerRadius={160}
        width={550}
        height={500}
        data={effectGraphData}
      >
        <Radar
          name='Strain Effects'
          dataKey='percentage'
          stroke='#8aca67'
          fill='#42b86a'
          fillOpacity={0.8}
        />
        <PolarGrid gridType='circle' />
        {/* <Tooltip /> */}
        <PolarAngleAxis
          dataKey='value'
          tick={
            (props) =>
              effectGraphDataTop3.includes(props.payload.value)
                ? CustomizedAxisTick(props, 'orange')
                : CustomizedAxisTick(props)
            // effectarr.length
            // ? console.log(CustomizedAxisTick(props), 'BITCHES')
            //   : null
          }
          orientation='outer'
        ></PolarAngleAxis>

        <PolarRadiusAxis tick={false} axisLine={false} />
        {/* LOGIC FOR DISPLAYING MULITPLE GRAPH LEGENDS */}
        {/* {effectSection.props.children[1].props.children.length > 5 ? (
          <Legend />
        ) : null} */}
      </RadarChart>
    </StyledEffectdiv>
  );
  // console.log(
  //   effectSection.props.children[1].props.children.length > 5,
  //   'HERE'
  // );

  // ---------------------------------------------

  // let descriptionSection = (
  //   <div className='straindesc' style={{ margin: '10%' }}>
  //     <ExpansionPanel defaultExpanded>
  //       <ExpansionPanelSummary
  //         expandIcon={<ExpandMoreIcon />}
  //         aria-controls='description content'
  //       >
  //         <h2>Description</h2>
  //       </ExpansionPanelSummary>
  //       <StyledDescText>{strain?.description}</StyledDescText>
  //     </ExpansionPanel>
  //   </div>
  // );

  // Issue: --  with Flavors

  let flavorsParsed = JSON.parse(strain?.flavors?.replace(/'/g, '"') ?? null);
  console.log(flavorsParsed, 'FLAV');

  let flavorSection = (
    <div className='flavor-section'>
      <StyledSectionHead>
        <h2>Flavors</h2>
        <p>
          {flavorsParsed?.map((e) => (
            <p>{e}</p>
          ))}
        </p>
      </StyledSectionHead>
    </div>
  );
  // Test
  //  TEREPENES --------------------
  let terp_dictionary = {
    Myrcene: 'Herbal',
    Pinene: 'Pine',
    Caryophyllene: 'Peppery',
    Limonene: 'Citrus',
    Humulene: 'Hoppy',
    Terpinolene: 'Fruity',
    Ocimene: 'Minty',
    Linalool: 'Floral',
  };

  let terpsParsed = JSON.parse(
    strain?.terpene_description?.replace(/'/g, '"') ?? null
  );
  let terpsPercentageParsed = strain?.technical_terpenes
    ?.trim()
    ?.replace(/'/g, '"')
    ?.replace('[', '')
    ?.replace(']', '')
    ?.replace(/"{/g, '"{')
    ?.replace(/}",/g, '}">')
    .split('>');

  // .split('}"');

  console.log(terpsPercentageParsed, 'Mobile fix');
  let terpsarr = [];

  let terparrloop = useCallback(
    () =>
      terpsPercentageParsed?.map((e) => {
        console.log(e, 'mobile fixx');
        terpsarr.push(JSON.parse(e.trim().slice(1, -1)));
      }),
    [terpsPercentageParsed]
  );

  terparrloop();

  let newterpArr = terpsarr?.map((e) => {
    return { ...e, terpine: Number(e.terpine.slice(0, -1)) };
  });

  let terpPercntage =
    newterpArr.length > 0
      ? newterpArr?.reduce((acc, cv) => acc + cv.terpine, 0)
      : null;

  //  Math.round((100 * (data.count * 100)) / (effectarr.length - 13)) / 100;

  newterpArr = newterpArr.map((terp) => {
    let dataPerc = Math.round((terp.terpine * 100) / terpPercntage);
    return {
      ...terp,
      name: terp_dictionary[terp.name],
      percentage: dataPerc,
    };
  });
  console.log('NULL?', terpsarr.length, terpPercntage, 'TERPPaPP');

  // let terpene_descriptionParsed = JSON.parse(
  //   strain?.technical_terpenes?.replace(/'/g, '"') ?? null
  // );

  let prnts = strain?.parents?.split(',');
  console.log(strain?.parents, 'YESSS');

  const colors = [
    '#1f77b4',
    '#ff7f0e',
    '#2ca02c',
    '#d62728',
    '#9467bd',
    '#8c564b',
    '#e377c2',
    '#7f7f7f',
    '#bcbd22',
    '#17becf',
  ];

  const getPath = (x, y, width, height) => `M${x},${y + height}
  C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${
    x + width / 2
  }, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
    x + width
  }, ${y + height}
  Z`;

  const TriangleBar = (props) => {
    const { fill, x, y, width, height } = props;

    return <path d={getPath(x, y, width, height)} stroke='none' fill={fill} />;
  };

  TriangleBar.propTypes = {
    fill: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  };

  let terpene_descriptionSection = (
    <div className='terepenes'>
      <StyledSectionHead>
        <h2 style={{ textAlign: 'center' }}>Terpenes</h2>

        {/* {terpsParsed?.map((e) => (
          <p>{e}</p>
        ))} */}
        {/* {terpsPercentageParsed?.map((e) => (
        <p>{e}</p>
      ))} */}

        <BarChart
          width={500}
          height={300}
          data={newterpArr}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} horizontal={false} />
          <XAxis dataKey='name' tick={CustomizedBarAxisTick} />
          <YAxis drawAxisLinesEnabled={false} tick={false} />
          {/* //should be able to remove this prop, I think there is a bug in Pie stopping labels from rendering. This is a temp fix. */}
          <Bar
            dot={false}
            isAnimationActive={false}
            dataKey='percentage'
            fill='#8884d8'
            shape={<TriangleBar />}
            label={renderCustomBarLabel}
          >
            {terpsarr?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % 20]} />
            ))}
          </Bar>
        </BarChart>
      </StyledSectionHead>
    </div>
  );

  let customArr = [];

  let defaultarr = [
    descriptionSection(strain),
    effectSection,
    terpsarr.length === 0 ? null : terpene_descriptionSection,
    flavorsParsed === null ? null : flavorSection,
  ];

  let customArrLoop = useCallback(() => {
    return currentUser?.view?.forEach((e) =>
      defaultarr.forEach((s) => {
        if (e === s?.props?.className) {
          customArr.push(s);
        } else {
          return null;
        }
      })
    );
  }, [customArr, defaultarr, currentUser]);
  customArrLoop();

  let testArr = [1, 2];

  // RETURN STARTS HERE ------------------------------

  return (
    <StyledStrainDiv>
      {isloading && (
        <Loader
          type='BallTriangle'
          color='#00BFFF'
          height={100}
          width={100}
          timeout={3000} //3 secs
        />
      )}
      {!isloading && (
        <>
          <StrainImg
            style={{ width: '30%' }}
            src={chkstrainImg || strain?.old_image || noImage}
            alt=''
          />
          <StyledStrainHeader>
            <StrainTitle>
              {/* Await THC stuff */}
              {strain?.name}{' '}
              <span>
                <StyledRating
                  name='customized-color'
                  defaultValue={0}
                  max={1}
                  getLabelText={(value) =>
                    `${value} Heart${value !== 1 ? 's' : ''}`
                  }
                  icon={<FavoriteIcon fontSize='large' />}
                />
              </span>
            </StrainTitle>
            <p>{strain?.race}</p>
          </StyledStrainHeader>

          {!currentUser?.view?.length
            ? defaultarr?.map((e) => <>{e}</>)
            : customArr?.map((e) => <>{e}</>)}
        </>
      )}
      {/* END OF MAPPED OVER SECTIONS */}
      <br />
      {strain?.parents !== null ? (
        <div style={{ marginTop: '10rem' }}>
          <h2 style={{ textAlign: 'center', fontSize: '2rem' }}>Lineage</h2>
          <Tree
            lineWidth={'5px'}
            lineColor={'green'}
            lineBorderRadius={'15px'}
            label={<StyledNode>{strain?.name}</StyledNode>}
          >
            {prnts?.map((e) => (
              <>
                <TreeNode label={<StyledNode>Parent</StyledNode>}>
                  <TreeNode
                    label={
                      <StyledNode
                        onClick={() =>
                          push(`/strains/${e?.trim()?.replace(/\s+/g, '_')}`)
                        }
                      >
                        {console.log(e, 'EEE')}
                        {e}
                      </StyledNode>
                    }
                  />
                </TreeNode>
              </>
            ))}
          </Tree>
        </div>
      ) : null}
      <Reviews
        toggleModal={toggleModal}
        showReview={showReview}
        strain={strain}
        setHover={setHover}
        labels={labels}
        hover={hover}
        currentReviews={currentReviews}
        setCurrentReviews={setCurrentReviews}
      />
    </StyledStrainDiv>
  );
};

export default Strains;
