import React from 'react';
import { Link } from 'react-router-dom';
import { QRCode } from 'react-qrcode-logo';

// Material UI
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// Styles
import { StyledStrainCard } from '../styles/StyledStrainCard';
import cardStyles from '../styles/StyledStrainCard';

// Images
import noImage from '../imgs/green-text-radar.png';
import qr1 from '../imgs/QRCode.png';
import qr2 from '../imgs/qrcode2.png';
import qr3 from '../imgs/qrcode3.png';

const StrainCards = ({ strain, sname }) => {
  const classes = cardStyles();

  let chkstrainImg = strain?.image === 'None' ? null : strain?.image;

  return (
    <Card className={classes.root}>
      <div className={classes.qrCont}>
        <QRCode
          bgColor='#ffff'
          fgColor='#324552'
          ecLevel='H'
          qrStyle='squares'
          logoWidth='70'
          quietZone='10'
          logoImage={qr1}
          logoOpacity='1'
          // style={{ marin: '0 auto' }}
          value={`https://s-w.now.sh/strains/${sname}`}
        />
      </div>
      <div style={{ border: '#324552 solid 10px' }}>
        <QRCode
          bgColor='#ffff'
          fgColor='#324552'
          ecLevel='H'
          qrStyle='squares'
          logoWidth='80'
          logoHeight='80'
          quietZone='5'
          logoImage={qr2}
          logoOpacity='1'
          style={{ marin: '0 auto' }}
          value={`https://s-w.now.sh/strains/${sname}`}
        />
      </div>
      <div style={{ border: '#324552 solid 10px' }}>
        <QRCode
          bgColor='#ffff'
          fgColor='#324552'
          ecLevel='H'
          qrStyle='squares'
          logoWidth='90'
          logoHeight='90'
          quietZone='5'
          logoImage={qr3}
          logoOpacity='1'
          style={{ marin: '0 auto' }}
          value={`https://s-w.now.sh/strains/${sname}`}
        />
      </div>
      <Button className={classes.strainBtn} href={`/strains/${sname}`}>
        <img
          className={classes.media}
          src={chkstrainImg || strain?.old_image || noImage}
          alt=''
        />
        <Typography className={classes.strainName}>{strain.name}</Typography>
      </Button>
    </Card>
  );
};

export default StrainCards;
