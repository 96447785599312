import React, { useEffect, useCallback } from 'react';
import {
  StyledButton,
  StyledSectionHead,
  Inputtextarea,
  StyledRatingform,
  StyledReviewDiv,
  StyledTitleInput,
  StyledStrainReviewFormDiv,
  StyledFab,
  StyledStrainCardReviewdiv,
  useStyles,
} from '../styles/StyledStrains';
// Material UI
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import Publish from '@material-ui/icons/Publish';
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useFormState } from '../hooks/useFormState';
import clsx from 'clsx';
import Axios from 'axios';

const Reviews = (props) => {
  const {
    toggleModal,
    showReview,
    strain,
    labels,
    setHover,
    currentReviews,
    hover,
    setCurrentReviews,
  } = props;
  const classes = useStyles();

  const [
    user,
    handlechange,
    // eslint-disable-next-line no-unused-vars
    handlesubmit,
    handleButtonClick,
    handlestarchange,
    success,
    loading,
    value,
  ] = useFormState(
    {
      id: null,
      title: ``,
      stars: 0,
      review: ``,
    },
    props
  );

  let strainid = strain?.id;

  const getReviews = useCallback(async () => {
    let ax = await Axios.get(
      `https://strainwatch-v1.herokuapp.com/api/strains/${strain?.id}/reviews`
    );
    setCurrentReviews(ax.data.reviews);
  }, [strainid]);

  useEffect(() => {
    getReviews();
  }, [success, getReviews]);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success === true,
  });

  console.log(currentReviews.length, 'YESS');
  return (
    <>
      <StyledSectionHead style={{ marginTop: '9rem', marginBottom: '3%' }}>
        <h2>Reviews</h2>

        {!currentReviews.length ? (
          <p>No current reviews, tell us about your experience .. </p>
        ) : null}
        <Button
          onClick={toggleModal}
          variant='contained'
          color='secondary'
          startIcon={<EditIcon />}
        >
          Write Review
        </Button>
        <p style={{ marginBottom: '4%' }}></p>
      </StyledSectionHead>

      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={showReview}
        onClose={toggleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showReview}>
          <div className={classes.paper}>
            <StyledStrainReviewFormDiv>
              <h2 style={{ marginBottom: '0' }}>
                &nbsp; Review {strain.name}{' '}
              </h2>
              <form
                id='ReviewForm'
                onSubmit={async (e) => {
                  await handleButtonClick(
                    e,
                    `https://strainwatch-v1.herokuapp.com/api/strains/${strain.id}/reviews`,
                    toggleModal
                  );
                }}
              >
                <fieldset style={{ border: `0` }} className='formfield'>
                  <label className='forms'>
                    <p
                      style={{
                        marginBottom: `8px`,
                        fontSize: `1rem`,
                      }}
                    >
                      Title your review
                    </p>
                    <StyledTitleInput
                      type='text'
                      name='title'
                      minLength='3'
                      placeholder=' Highlight interesting details'
                      value={user.title}
                      onChange={handlechange}
                    />
                  </label>
                  <StyledReviewDiv>
                    <StyledRatingform>
                      <Rating
                        name='stars'
                        type='radio'
                        size='large'
                        value={value}
                        className={classes.stars}
                        onChange={handlestarchange}
                        onChangeActive={(event, newHover) => {
                          setHover(newHover);
                        }}
                      />
                      <Box ml={2}>{labels[hover !== -1 ? hover : value]}</Box>
                    </StyledRatingform>
                    <Inputtextarea
                      placeholder='Tell people about your experience'
                      type='text'
                      name='review'
                      cols='50'
                      rows='10'
                      value={user.review}
                      onChange={handlechange}
                    ></Inputtextarea>
                  </StyledReviewDiv>
                  <div className={classes.wrapper}>
                    <StyledButton
                      variant='contained'
                      color='inherit'
                      type='submit'
                      size='large'
                      className={buttonClassname}
                      disabled={loading}
                    >
                      Post Review
                    </StyledButton>
                    &nbsp;&nbsp;
                    {success ? (
                      <StyledFab
                        aria-label='save'
                        type='submit'
                        color='inherit'
                        className={buttonClassname}
                      >
                        <CheckIcon className='checkIcon' color='inherit' />
                      </StyledFab>
                    ) : null}
                    {loading && (
                      <CircularProgress
                        size={65}
                        className={classes.fabProgress}
                        color='inherit'
                      />
                    )}
                  </div>
                </fieldset>
              </form>
            </StyledStrainReviewFormDiv>
          </div>
        </Fade>
      </Modal>
      {currentReviews?.map((review) => (
        <>
          <StyledStrainCardReviewdiv key={review.id}>
            <h3>{review.title}</h3>
            <Rating
              name='half-rating'
              value={review.rating}
              precision={0.5}
              readOnly
            ></Rating>
            <h4>{review.email}</h4>
            <p>{review.review}</p>
          </StyledStrainCardReviewdiv>
        </>
      ))}
    </>
  );
};

export default Reviews;
