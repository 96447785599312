import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';



export const StyledStrainCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    text-align: center;
    margin: 0;
    margin-bottom: 5px;
  }
  img {
    width: 250px;
    height: 280px;
    /* max-height: 350px; */
    transition: all 0.3s;
    object-fit: cover;
    border-radius: 20px;

    :hover {
      opacity: 0.8;
    }

    /* @media screen and (max-width: 1024px) {
      height: 300px;
    } */

    /* @media screen and (max-width: 768px) {
      height: 350px;
    }

    @media screen and (max-width: 600px) {
      max-height: 300px;
    }

    @media screen and (max-width: 375px) {
      max-height: 450px;
    } */

    .clickable {
      cursor: pointer;
    }
  }
`;

const cardStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '.5rem',
    hover: {
      opacity: 0.8
    }
  },
  strainBtn: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      },
  },
  media: {
    height: 0,
    width: '100px',
    height: '120px',
    /* max-height: 350px; */
    transition: 'all 0.3s',
    objectFit: 'cover',
    borderRadius: '20px',
  },
  // qrCont: {
  //   border: '#324552 solid 10px',
  //   display: "flex",
  //   justifyContent: "center"
  // },
}));

export default cardStyles;

export const CardStyled = styled(Button)`
  &&& {
    display: flex;
    flex-direction: column;
  }
`;