import React, { useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
// import '../styles/Register_login.scss';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { useStyles } from '../styles/StyledRegister_login';
import Container from '@material-ui/core/Container';
import { useDispatch } from 'react-redux';

const Register_Login = () => {
  const [state, setstate] = useState({
    email: '',
    password: '',
  });
  const [signup, setsignup] = useState(false);
  const { push } = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  function Copyright() {
    return (
      <Typography variant='body2' color='textSecondary' align='center'>
        {'Copyright © '}
        <Link color='inherit' href='https://material-ui.com/'>
          Strain Watch
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  const handlechange = (e) => {
    e.preventDefault();
    setstate({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Axios.post('https://strainwatch-v1.herokuapp.com/api/auth/login', state)
      .then((res) => {
        console.log(res, `success`);
        localStorage.setItem('token', res.data.token);
        dispatch({ type: 'LOGGED_STATUS', payload: true });
        push(`/strains`);
      })
      .catch((err) => console.log(err) & alert('Invalid Email or Password'));
  };
  const handleSubmitSignup = (e) => {
    e.preventDefault();
    Axios.post('https://strainwatch-v1.herokuapp.com/api/auth/register', state)
      .then(
        (res) =>
          alert('Sign up successful') &
          localStorage.setItem('token', res.data.token) &
          dispatch({ type: 'LOGGED_STATUS', payload: true }) &
          push(`/strains`)
      )
      .catch(
        (err) =>
          console.log(err) &
          alert('Email already exist, please signin or choose another one')
      );
  };

  return (
    <div>
      {!signup ? (
        <>
          <Grid container component='main' className={classes.root}>
            <CssBaseline />
            {console.log(state)}
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid
              item
              xs={12}
              sm={8}
              md={5}
              component={Paper}
              elevation={6}
              square>
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component='h1' variant='h5'>
                  Sign in
                </Typography>
                <form
                  onSubmit={handleSubmit}
                  className={classes.form}
                  noValidate>
                  <TextField
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    id='email'
                    label='Email Address'
                    name='email'
                    autoComplete='email'
                    value={state.email}
                    type='text'
                    onChange={handlechange}
                    autoFocus
                  />
                  <TextField
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    name='password'
                    label='Password'
                    type='password'
                    id='password'
                    autoComplete='current-password'
                    value={state.password}
                    onChange={handlechange}
                  />
                  <FormControlLabel
                    control={<Checkbox value='remember' color='primary' />}
                    label='Remember me'
                  />
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    className={classes.submit}>
                    Sign In
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link href='#' variant='body2'>
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        style={{ cursor: 'pointer' }}
                        onClick={() => setsignup(!signup)}
                        variant='body2'>
                        {"Don't have an account? Sign Up"}
                      </Link>
                    </Grid>
                  </Grid>
                  <Box mt={5}>
                    <Copyright />
                  </Box>
                </form>
              </div>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component='h1' variant='h5'>
                Sign up
              </Typography>
              <form
                onSubmit={handleSubmitSignup}
                className={classes.form}
                noValidate>
                <Grid container spacing={2}>
                  {/* <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete='fname'
                      name='firstName'
                      variant='outlined'
                      required
                      fullWidth
                      id='firstName'
                      label='First Name'
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant='outlined'
                      required
                      fullWidth
                      id='lastName'
                      label='Last Name'
                      name='lastName'
                      autoComplete='lname'
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <TextField
                      variant='outlined'
                      required
                      fullWidth
                      id='email'
                      label='Email Address'
                      name='email'
                      autoComplete='email'
                      value={state.email}
                      type='text'
                      onChange={handlechange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant='outlined'
                      required
                      fullWidth
                      name='password'
                      label='Password'
                      type='password'
                      id='password'
                      value={state.password}
                      onChange={handlechange}
                      autoComplete='current-password'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox value='allowExtraEmails' color='primary' />
                      }
                      label='I want to receive inspiration, marketing promotions and updates via email.'
                    />
                  </Grid>
                </Grid>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.submit}>
                  Sign Up
                </Button>
                <Grid container justify='flex-end'>
                  <Grid item>
                    <Link
                      onClick={() => setsignup(!signup)}
                      style={{ cursor: 'pointer' }}
                      variant='body2'>
                      Already have an account? Sign in
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
            <Box mt={5}>
              <Copyright />
            </Box>
          </Container>
        </>
      )}
    </div>
  );
};

export default Register_Login;
