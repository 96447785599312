import Axios from 'axios';

const url = 'https://strainwatch-v1.herokuapp.com/api';

export const axiosWithAuth = () => {
  return Axios.create({
    baseURL: url,
    headers: {
      Authorization: localStorage.getItem('token'),
    },
  });
};
