import { axiosWithAuth } from '../utils/axioswithauth';
import { DATA_FAILURE, DATA_START, DATA_SUCCESS } from '../reducers';
import Axios from 'axios';

export const FetchOne = (url) => (dispatch) => {
  dispatch({ type: DATA_START });

  Axios.get(url)
    .then(
      (res) =>
        console.log(res, 'res data') &
        dispatch({ type: DATA_SUCCESS, payload: res.data })
    )
    .catch(
      (err) =>
        console.log(err, 'ERROR') &
        dispatch({ type: DATA_FAILURE, payload: err })
    );
};
export const FetchStrains = (url) => (dispatch) => {
  dispatch({ type: DATA_START });

  Axios.get(url)
    .then(
      (res) =>
        console.log(res, 'res data') &
        dispatch({ type: DATA_SUCCESS, strain: res.data })
    )
    .catch(
      (err) =>
        console.log(err, 'ERROR') &
        dispatch({ type: DATA_FAILURE, strain: err })
    );
};
export const FetchSearchResults = (url) => (dispatch) => {
  dispatch({ type: DATA_START });

  Axios.get(url)
    .then(
      (res) =>
        console.log(res, 'res data') &
        dispatch({ type: DATA_SUCCESS, searchResults: res.data })
    )
    .catch(
      (err) =>
        console.log(err, 'ERROR') &
        dispatch({ type: DATA_FAILURE, searchResults: err })
    );
};
export const Fetchusers = () => (dispatch) => {
  dispatch({ type: DATA_START });

  axiosWithAuth()
    .get('/users/user')
    .then(
      (res) =>
        console.log(res, 'res data') &
        dispatch({ type: DATA_SUCCESS, currentuser: res.data })
    )
    .catch(
      (err) =>
        console.log(err, 'ERROR') &
        dispatch({ type: DATA_FAILURE, currentuser: '', error: err.message })
    );
};

//  WILL NEED TO CHANGE DISPATCH --- IF I ADD IN SOME KIND OF FORM LATER

// export const Send = (url, data) => (dispatch) => {
//   dispatch({ type: DATA_START });
//   axiosWithAuth()
//     .post(url, data)
//     .then((res) => {
//       console.log(res, 'Sent data');
//       dispatch({ type: DATA_SUCCESS, payload: res.data });
//     })
//     .catch((err) => {
//       dispatch({ type: DATA_FAILURE, payload: err.response });
//     });
// };

// export const Edit = (id, data) => (dispatch) => {
//   dispatch({ type: DATA_START });
//   axiosWithAuth()
//     .put(`/item/${id}`, data)
//     .then((res) => {
//       console.log(res, 'Deleted data');
//       dispatch({ type: DATA_SUCCESS });
//       // dispatch(Fetch());
//     })
//     .catch((err) => {
//       dispatch({ type: DATA_FAILURE, payload: err.response });
//     });
// };

// export const Delete = (id) => (dispatch) => {
//   dispatch({ type: DATA_START });
//   axiosWithAuth()
//     .delete(`/item/${id}`)
//     .then((res) => {
//       console.log(res, 'Deleted data');
//       dispatch({ type: DATA_SUCCESS });
//       // dispatch(Fetch());
//     })
//     .catch((err) => {
//       dispatch({ type: DATA_FAILURE, payload: err.response });
//     });
// };
