import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { StyledDescText } from '../styles/StyledStrains';

export const CustomizedAxisTick = (props, cb) => {
  const { x, y, stroke, payload } = props;
  let fillColor = 'white';

  return (
    <g transform={`translate(${x},${y})`}>
      {payload.coordinate === -242.30769230769232 ? (
        <text
          x={0}
          y={0}
          dy={0}
          textAnchor='middle'
          fill={cb ?? fillColor}
          transform='rotate(332)'
        >
          {payload.value}
        </text>
      ) : payload.coordinate === 62.307692307692335 ? (
        <text
          x={0}
          y={0}
          dy={0}
          textAnchor='middle'
          fill={cb ?? fillColor}
          transform='rotate(27.69)'
        >
          {payload.value}
        </text>
      ) : payload.coordinate === -131.53846153846155 ? (
        <text
          x={0}
          y={5}
          dy={0}
          textAnchor='middle'
          fill={cb ?? fillColor}
          transform='rotate(40)'
        >
          {payload.value}
        </text>
      ) : payload.coordinate === 34.6153846153846 ? (
        <text
          x={0}
          y={0}
          dy={0}
          textAnchor='middle'
          fill={cb ?? fillColor}
          transform='rotate(55.38)'
        >
          {payload.value}
        </text>
      ) : payload.coordinate === 6.92307692307692 ? (
        <text
          x={0}
          y={0}
          dy={0}
          textAnchor='middle'
          fill={cb ?? fillColor}
          transform='rotate(83.07)'
        >
          {payload.value}
        </text>
      ) : payload.coordinate === -90 ? (
        <text
          x={0}
          y={5}
          dy={0}
          textAnchor='middle'
          fill={cb ?? fillColor}
          transform='rotate(0)'
        >
          {payload.value}
        </text>
      ) : payload.coordinate === -20.76923076923076 ? (
        <text
          x={0}
          y={5}
          dy={0}
          textAnchor='middle'
          fill={cb ?? fillColor}
          transform='rotate(290.76)'
        >
          {payload.value}
        </text>
      ) : payload.coordinate === -48.46153846153844 ? (
        <text
          x={0}
          y={5}
          dy={0}
          textAnchor='middle'
          fill={cb ?? fillColor}
          transform='rotate(318.46)'
        >
          {payload.value}
        </text>
      ) : payload.coordinate === -76.15384615384615 ? (
        <text
          x={0}
          y={5}
          dy={0}
          textAnchor='middle'
          fill={cb ?? fillColor}
          transform='rotate(346.15)'
        >
          {payload.value}
        </text>
      ) : payload.coordinate === -103.84615384615385 ? (
        <text
          x={0}
          y={5}
          dy={0}
          textAnchor='middle'
          fill={cb ?? fillColor}
          transform='rotate(13)'
        >
          {payload.value}
        </text>
      ) : payload.coordinate === -159.23076923076923 ? (
        <text
          x={0}
          y={5}
          dy={0}
          textAnchor='middle'
          fill={cb ?? fillColor}
          transform='rotate(69)'
        >
          {payload.value}
        </text>
      ) : payload.coordinate === -186.9230769230769 ? (
        <text
          x={0}
          y={0}
          dy={0}
          textAnchor='middle'
          fill={cb ?? fillColor}
          transform='rotate(276)'
        >
          {payload.value}
        </text>
      ) : payload.coordinate === -214.61538461538464 ? (
        <text
          x={0}
          y={0}
          dy={0}
          textAnchor='middle'
          fill={cb ?? fillColor}
          transform='rotate(304.615)'
        >
          {payload.value}
        </text>
      ) : (
        <text
          x={0}
          y={0}
          dy={0}
          textAnchor='middle'
          fill={cb ?? fillColor}
          transform='rotate(0)'
        >
          {payload.value}
        </text>
      )}
    </g>
  );
};

export let descriptionSection = (strain) => (
  <div className='straindesc' style={{ margin: '10%' }}>
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='description content'
      >
        <h2>Description</h2>
      </ExpansionPanelSummary>
      <StyledDescText>{strain?.description}</StyledDescText>
    </ExpansionPanel>
  </div>
);

export const CustomizedBarAxisTick = (props) => {
  const { x, y, stroke, payload, dot, visibleTicksCount } = props;
  console.log(props, 'WHAT ARE YOU');

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={15}
        dy={0}
        textAnchor='middle'
        fill='#666'
        transform='rotate(0)'
      >
        {payload.value}
      </text>
    </g>
  );
};
export const renderCustomBarLabel = (props) => {
  let { payload, x, y, width, height, value, fill } = props;
  console.log(fill, props, 'Terppp');
  return (
    <text
      x={x + width / 2}
      y={y}
      fill={fill}
      textAnchor='middle'
      dy={-6}
    >{` ${value}%`}</text>
  );
};
