export const DATA_START = 'DATA_START';
export const DATA_SUCCESS = 'DATA_SUCCESS';
export const DATA_FAILURE = 'DATA_FAILURE';
export const FORM_CHANGE = 'FORM_CHANGE';
export const RESET_FORM = 'RESET_FORM';
export const EDIT_CHANGE = 'EDIT_CHANGE';
export const CHANGE_PAGE = 'CHANGE_PAGE';
// const uuidv4 = require("uuid/v4");

// const setid = window.localStorage.getItem('CURRENTUSER');

const initialState = {
  currentuser: '',
  users: [],
  loggedin: false,
  page: 1,
  isloading: false,
  favorites: [],
  data: [],
  strains: [],
  recommendedList: [],
  view: [],
  view_type: '',
  searchResults: [],
  error: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DATA_START:
      return {
        ...state,
        isloading: true,
      };
    case DATA_SUCCESS:
      return {
        ...state,
        isloading: false,
        data: action.payload || state.data,
        currentuser: action.currentuser || state.currentuser,
        strains: action.strain || state.strains,
        searchResults: action.searchResults || state.searchResults,
      };
    case DATA_FAILURE:
      return {
        ...state,
        data: action.payload || state.data,
        currentuser: action.currentuser || state.currentuser,
        strains: action.strain || state.strains,
        error: action.error || state.error,
        isloading: false,
      };
    // case FORM_CHANGE:
    //   return {
    //     ...state,
    //     strain: {
    //       ...state.strain,
    //       [action.name]: action.value,
    //     },
    //   };

    case 'LOGGED_STATUS':
      return {
        ...state,
        loggedin: action.payload,
        currentuser: action.status,
      };
    case 'CURRENT_USER':
      return {
        ...state,
        currentuser: action.payload,
      };

    case CHANGE_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    default:
      return state;
  }
};
